



































import { Component, Prop, Vue } from 'vue-property-decorator';

import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Loader from '@/shared/resources/components/Loader.vue';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import IconMessage from '@/shared/resources/components/IconMessage.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import { redirect } from '@/shared/lib/support/router/RouterUtils';
import { bankData } from '@/shared/lib/config/generalConfig';
import { PaymentsEnum } from '@/modules/payments/enums/PaymentsEnum';

import CartOrderSuccessPayu from './partials/success/CartOrderSuccessPayu.vue';
import CartOrderSuccessMBankPaynow from './partials/success/CartOrderSuccessMBankPaynow.vue';
import CartOrderSuccessOnDelivery from './partials/success/CartOrderSuccessOnDelivery.vue';
import CartOrderSuccessBankWire from './partials/success/CartOrderSuccessBankWire.vue';
import Order from '@/app/lib/order/Order';

@Component({
  components: {
    CartOrderSuccessBankWire,
    CartOrderSuccessOnDelivery,
    CartOrderSuccessMBankPaynow,
    CartOrderSuccessPayu,
    Button,
    Divider,
    IconMessage,
    Loader,
    GridCol,
    GridRow,
  },
})
export default class CartOrderSuccess extends Vue {
  /**
   * Props
   */
  @Prop() private orderId!: number;

  /**
   * Data
   */
  private order: OnlineOrderCoreModel | null = null;
  private loading: boolean = true;

  /**
   * Getters
   */
  private get bankData(): string {
    return bankData;
  }

  private get isProcessing(): boolean {
    return true;
    return Order.processing;
  }

  private get successComponent(): string | null {
    if (!this.order || !this.isProcessing) {
      return null;
    }

    if (this.order.payment === PaymentsEnum.PAYU) {
      return 'CartOrderSuccessPayu';
    } else if (this.order.payment === PaymentsEnum.PAYNOW) {
      return 'CartOrderSuccessMBankPaynow';
    } else if (this.order.payment === PaymentsEnum.BANKWIRE) {
      return 'CartOrderSuccessBankWire';
    }

    return 'CartOrderSuccessOnDelivery';
  }

  /**
   * Display getters
   */
  private get displayContent(): boolean {
    return !this.loading && !!this.order && this.isProcessing;
  }

  private get displayProcessedError(): boolean {
    return !this.isProcessing;
  }

  private get displayLoader(): boolean {
    return this.loading;
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    this.getOrder();
    Order.reset();
  }

  /**
   * Methods
   */
  private async getOrder() {
    this.loading = true;

    try {
      this.order = await OnlineOrderCoreModel
        .apiExt()
        .param('id', this.orderId)
        .get();
    } catch (ex) {
      // TODO
    }

    this.loading = false;
  }

  private goToDashboard() {
    redirect({ name: 'dashboard' });
  }

  /**
   * Handlers
   */
  private onGoToDashboardButtonClick() {
    this.goToDashboard();
  }
}
