




















import { Vue, Component, Prop } from 'vue-property-decorator';

import { redirect } from '@/shared/lib/support/router/RouterUtils';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';

@Component({
  components: {
    Divider,
    Button,
  },
})
export default class CartOrderSuccessOnDelivery extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;

  /**
   * Methods
   */
  private goToDashboard() {
    redirect({ name: 'dashboard' });
  }

  /**
   * Handlers
   */
  private onGoToDashboardButtonClick() {
    this.goToDashboard();
  }
}
