





















import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import md5 from 'crypto-js/md5';

import Button from '@/shared/resources/components/buttons/Button.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import GridSpacer from '@/shared/resources/components/grid/GridSpacer.vue';
import Api from '@/core/api/Api';
import PayuSettings from '@/shared/lib/interfaces/PayuSettings';
import { checkIP } from '@/core/helpers/utils/SystemUtils';
import MBankPaynowSettings from '@/shared/lib/interfaces/MBankPaynowSettings';

@Component({
  components: {
    GridSpacer,
    Divider,
    Button,
  },
})
export default class CartOrderSuccessPayu extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;

  /**
   * Data
   */
  private paynowSettings: MBankPaynowSettings | null = null;
  private redirectTime: number = 7 * 1000;

  /**
   * Lifecycle hooks
   */
  private async created() {
    await this.getSettings();
    this.setRedirectTimeout();
  }

  /**
   * Methods
   */
  private async getSettings() {
    try {
      const result: any = await new Api().get('/settings/mbank-paynow/' + this.order.id);

      this.paynowSettings = result.data;
    } catch (ex) {
      // TODO handle error
    }
  }

  private proceed() {
    if (!this.paynowSettings) {
      return;
    }

    window.location.href = this.paynowSettings.redirectUrl;
  }

  private setRedirectTimeout() {
    setTimeout(() => {
      this.proceed();
    }, this.redirectTime);
  }
}
