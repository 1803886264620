



































import { Vue, Component, Prop } from 'vue-property-decorator';

import { redirect } from '@/shared/lib/support/router/RouterUtils';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import { bankData } from '@/shared/lib/config/generalConfig';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import Alert from '@/shared/resources/components/Alert.vue';

@Component({
  components: {
    Alert,
    Divider,
    Button,
  },
})
export default class CartOrderSuccessBankWire extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;

  /**
   * Getters
   */
  private get bankData(): string {
    return bankData;
  }

  /**
   * Methods
   */
  private goToDashboard() {
    redirect({ name: 'dashboard' });
  }

  /**
   * Handlers
   */
  private onGoToDashboardButtonClick() {
    this.goToDashboard();
  }
}
